#InviteResponse-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
}

@media (max-width: 800px) {
  #InviteResponse-footer {
    flex-direction: column;
  }
}

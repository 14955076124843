#EmailCrawlerModal {
}

#EmailCrawlerModal #EmailBoxUI:hover {
  background-color: #e5ecff;
}

#EmailTableModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

#EmailTableModal .header-psi {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

#EmailTableModal .header-psi .item-psi {
  flex: 1 1 0%;
}

#EmailTableModal .animate-bar {
  margin-top: 12px;
  height: 4px;
  width: 100%;
  border-radius: 8px;
  background-color: #7b9efd;
  position: relative;
}
#EmailTableModal .pdf-tag {
  background-color: red;
  color: white;
  display: flex;
  width: 100px;
  line-height: normal;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  padding: 6px;
  border-radius: 16px;
  overflow: hidden;
}
#EmailTableModal .pdf-tag > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

#EmailTableModal .animate-bar-line {
  background: #103996;
  border-radius: 8px;
  position: absolute;
  left: 0;
  z-index: 1;
  width: 50px;
  height: 4px;
  animation: line-bounce 2s infinite;
}

@keyframes line-bounce {
  0% {
    left: calc(100% - 50px);
  }
  50% {
    left: 0%;
  }
  100% {
    left: calc(100% - 50px);
  }
}

.cat-table-grid-x1 {
  margin: 16px 0px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 6 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 16px; /* Space between grid items */
}
/* Responsive adjustments */
@media (max-width: 1200px) {
  .cat-table-grid-x1 {
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
  }
}

@media (max-width: 768px) {
  .cat-table-grid-x1 {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
  }
}

@media (max-width: 480px) {
  .cat-table-grid-x1 {
    grid-template-columns: repeat(1, 1fr); /* 1 column */
  }
}

.CustomSimpleTable-com {
  width: 100%;
  border-collapse: collapse;
  /* border: 2px solid #3d71ff; */
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
}

.CustomSimpleTable-com th,
.CustomSimpleTable-com td {
  padding: 4px 8px;
  text-align: left;
  /* border-bottom: 1px solid #3d71ff; */
}

.CustomSimpleTable-com thead {
  background-color: #3d71ff;
  color: white;
  border-radius: 4px;
}

.CustomSimpleTable-com th {
  font-weight: bold;
}

.CustomSimpleTable-com tbody tr:last-child td {
  border-bottom: none;
}

#SafalWarrantyStoreRetailer {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 12px;
}

#SafalWarrantyStoreRetailer .title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  margin-top: 12px;
}

#SafalWarrantyStoreRetailer .title-bar .title-search-div {
  display: flex;
  align-items: center;
  gap: 32px;
}
#SafalWarrantyStoreRetailer .title-bar .title-search-div > h1 {
  color: #3d71ff;
}
#SafalWarrantyStoreRetailer .toggle-bar-dix {
  display: flex;
  align-items: center;
}
#SafalWarrantyStoreRetailer .CheckboxAction {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

#SafalWarrantyStoreRetailer .toggle-bar {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

#SafalWarrantyStoreRetailer .main-pagination {
  display: flex;
  justify-content: end;
}

#SafalWarrantyStoreRetailer .title-action-div {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
}
#SafalWarrantyStoreRetailer .card-view-switch {
  display: flex;
  align-items: center;
}

#SafalWarrantyStoreRetailer .main-content {
  border-radius: 12px;
  flex: 1 1 0%;
  background-color: white;
  overflow: auto;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px;
}

@media (max-width: 1000px) {
  #SafalWarrantyStoreRetailer .main-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 16px;
    gap: 16px;
  }
}

@media (max-width: 700px) {
  #SafalWarrantyStoreRetailer .main-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 12px;
    gap: 12px;
  }
  #SafalWarrantyStoreRetailer .title-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #SafalWarrantyStoreRetailer .title-bar .title-search-div {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .sm-flex-direction-column {
    flex-direction: column;
  }
  #SafalWarrantyStoreRetailer .title-action-div {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  #SafalWarrantyStoreRetailer .toggle-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #SafalWarrantyStoreRetailer .CheckboxAction {
    margin-left: 8px;
  }
}

/* Override Ant Design image preview mask z-index */
#SafalWarrantyStoreRetailer .ant-image-preview-mask {
  z-index: 1050; /* Adjust to a high number to ensure it's on top */
}

/* Override Ant Design image preview image container z-index */
#SafalWarrantyStoreRetailer .ant-image-preview-wrap {
  z-index: 1050; /* Match the z-index value */
}

#UserPlanList {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  /* gap: 12px; */
}
#UserPlanList .blue-bg-x1 {
  background-color: #89a7fa;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 40%;
  min-height: 250px;
  width: 100%;
}
#UserPlanList .main-div-heading-sw {
  display: flex;
  align-items: center;
  gap: 12px;
}
#UserPlanList .main-div-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-top: 32px;
  margin-left: 40px;
}
#UserPlanList .main-div-list {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}
#UserPlanList .PlanBox {
  width: 300px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  min-height: 400px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
#UserPlanList .main-div {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 32px;
}

@media (max-width: 1000px) {
}

@media (max-width: 700px) {
}

#SingleCommentItem .comments-x1-p {
  position: relative;
}
#SingleCommentItem .comments-x1-span {
  display: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 32px;
  font-weight: bold;
  color: #5582cc;
}

#SingleCommentItem .comments-x1-p:hover > .comments-x1 {
  filter: blur(4px);
  user-select: none;
  cursor: pointer;
}
#SingleCommentItem .comments-x1-p:hover > .comments-x1-span {
  display: block;
  user-select: none;
  cursor: pointer;
}

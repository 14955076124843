.AgoraUI {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
}

.AgoraUI .CallPopUp {
  position: relative;
  background-color: white;
  display: flex;
  padding: 20px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.AgoraUI .CallPopUp-btn-div {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.AgoraUI .CallPopUp .Time {
  border-radius: 8px;
  background-color: #202020;
  color: white;
  width: 82px;
  height: 32px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.AgoraUI .CallPopUp .Call-Accept {
  border-radius: 8px;
  background-color: #01b855;
  width: 72px;
  height: 32px;
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
}
.AgoraUI .CallPopUp .Call-Reject {
  border-radius: 8px;
  background-color: #fe453d;
  width: 72px;
  height: 32px;
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
}

#InitialSubscriptionFeedback {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 12px;
}

#InitialSubscriptionFeedback .title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  margin-top: 12px;
}

#InitialSubscriptionFeedback .title-bar .title-search-div {
  display: flex;
  align-items: center;
  gap: 32px;
}
#InitialSubscriptionFeedback .title-bar .title-search-div > h1 {
  color: #3d71ff;
}
#InitialSubscriptionFeedback .CheckboxAction {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

#InitialSubscriptionFeedback .main-pagination {
  display: flex;
  justify-content: end;
}

#InitialSubscriptionFeedback .title-action-div {
  flex: none;
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
}
#InitialSubscriptionFeedback .card-view-switch {
  display: flex;
  align-items: center;
}

#InitialSubscriptionFeedback .main-content {
  border-radius: 12px;
  flex: 1 1 0%;
  background-color: white;
  overflow: auto;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px;
}

#InitialSubscriptionFeedback .data-info-x {
  display: flex;
  flex-direction: column;
}
#InitialSubscriptionFeedback .data-info-x-c1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 20px;
  gap: 16px;
}
#InitialSubscriptionFeedback .data-info-x-c1.r {
  flex-direction: row-reverse;
}
#InitialSubscriptionFeedback .data-info-x-c1-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #027c34;
}
#InitialSubscriptionFeedback .data-info-x-c1-content {
  display: flex;
  gap: 16px;
  border: 1px solid #027c34;
  border-radius: 8px;
  background-color: #eaffea;
  color: #027c34;
}
#InitialSubscriptionFeedback .data-info-x-c2 {
  display: flex;
  flex-direction: column;
  width: 50px;
  align-items: center;
}
#InitialSubscriptionFeedback .data-info-x-d {
  flex: 1;
  height: 2px;
  background-color: #90ee90;
  border-radius: 8px;
}
/* #InitialSubscriptionFeedback .data-info-x-c1{} */

@media (max-width: 1000px) {
  #InitialSubscriptionFeedback .main-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 16px;
    gap: 16px;
  }
}

@media (max-width: 700px) {
  #InitialSubscriptionFeedback .main-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 12px;
    gap: 12px;
  }
  #InitialSubscriptionFeedback .title-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #InitialSubscriptionFeedback .title-bar .title-search-div {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
    gap: 12px;
  }
  .sm-flex-direction-column {
    flex-direction: column;
  }
  #InitialSubscriptionFeedback .title-action-div {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  #InitialSubscriptionFeedback .CheckboxAction {
    margin-left: 8px;
  }
}

#HelpVideo {
  height: 100%;
}
.HelpVideo-banner {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;
}

@media (max-width: 1000px) {
  .HelpVideo-banner {
    gap: 16px;
  }
}
@media (max-width: 800px) {
  .HelpVideo-banner {
    flex-direction: column-reverse;
  }
}

#HelpVideo a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

#AssetModuleBusiness {
  height: 100%;
  overflow: auto;
}
#AssetModuleBusiness .action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 12px;
}
#AssetModuleBusiness .select-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 12px;
  gap: 12px;
}
#AssetModuleBusiness .select-bar .select-bar-btn {
  display: flex;
  gap: 12px;
}
/* #AssetModuleBusiness .select-bar .select-bar-toggle {} */

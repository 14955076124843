@font-face {
  font-family: 'Proxima Nova Lt';
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Semibold.eot');
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Semibold.woff2') format('woff2'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Semibold.woff') format('woff'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Semibold.ttf') format('truetype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Th';
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNovaT-Thin.eot');
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNovaT-Thin.woff2') format('woff2'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNovaT-Thin.woff') format('woff'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNovaT-Thin.ttf') format('truetype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Regular.eot');
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Regular.woff2') format('woff2'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Regular.woff') format('woff'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Regular.ttf') format('truetype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Bold.eot');
  src: url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Bold.woff2') format('woff2'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Bold.woff') format('woff'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Bold.ttf') format('truetype'),
    url('../../public/assets/fonts/Proxima\ Nova/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: 'Proxima Nova Rg', sans-serif !important;

  /* overflow-x: hidden; */
}

/* hide react error overlay */
body > iframe {
  display: none;
}

p {
  margin-bottom: 0;
}

.chat-bot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 300;
  padding: 1rem;
  margin: 2rem;
  height: 100px;
  width: 100px;
  cursor: pointer;
}

.chat-bot1 {
  position: fixed;
  bottom: 87px;
  right: 46px;
  margin: 2rem;
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
}
.red-dot {
  background: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.home {
  background-color: #c8e6c9;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: #f5f5f5;
  border-radius: 20px;
  padding: 20px 30px;
}

.chat {
  display: flex;
  flex-direction: column;
}

.navbar {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
}

.logo {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height */

  color: #1d1d1f;
}

.search {
  border-bottom: 1px solid #dfe6f3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 64px;
}

.searchForm {
  padding: 10px;
  flex: none;
}

.searchForm input {
  border: none;
  color: #000000;
  outline: none;
  width: 100%;
  padding: 10px;

  border-radius: 30px;
}

.searchForm input::placeholder {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #b6b6b6;
}

.userChat {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  align-items: center;
  background: #fafafa;
  gap: 10px;
  cursor: pointer;
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */
  color: #1d1d1f;
}

.userChat:hover {
  background-color: #bcffdb;
}

.userChat.selected {
  background-color: #bcffdb;
}

.chat {
  flex: 2;
}

.chatInfo {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  /* font-weight: 700; */
  font-size: 18px;
  line-height: 20px;
  /* identical to box height */

  color: #1d1d1f;
}

.messages {
  flex: 1 1 0%;
  background-color: #ffffff;
  padding: 10px;
  height: calc(100% - 160px);
  margin-right: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.message-img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.send-container {
  background: #01b855;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  position: relative;
}

.send-container .send-ico {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%) rotate(-46deg);
}

.userChatName {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #1d1d1f;
}

.userChatMsg {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #1d1d1f;
}

.userChatInfo {
  overflow: hidden;
}

.chat {
  background: #ffffff;
}

.input {
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
  margin: 10px;
  border-radius: 10px;
}

.above_input {
  /* height: 200px; */
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
  margin-top: -100px;
  border-radius: 10px;
}

.input input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  background: #f3f3f3;
}

.input input::placeholder {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */
  color: #1d1d1f;
}

.input textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  background: #f3f3f3;
}

.input textarea::placeholder {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  padding-top: 10px;
  /* identical to box height */
  color: #1d1d1f;
}

.send {
  display: flex;
  align-items: center;
  gap: 10px;
}

.send button {
  border: none;
  padding: 10px 15px;
  color: white;
  background-color: #2065d1;
}

.message {
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
  margin-bottom: 20px;
}

.message1 {
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
  margin-bottom: 25px;
  margin-right: 20px;
}

.messageInfo {
  display: flex;
  flex-direction: column;
  color: gray;
  font-weight: 300;
}

.messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: max-content;
  padding: 10px 10px;
}

.messageContent .person {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
}

.message .time {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */

  color: #b6b6b6;
}

.messageContent .text {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.message-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.message-container.sent {
  flex-direction: row-reverse;
}

.messageContent.recieve {
  background: #eaeaea;
  border-radius: 10px 10px 10px 0px;
  color: #1d1d1f;
}

.messageContent.typing {
  background: #eaeaea;
  border-radius: 10px 10px 10px 0px;
  color: #1d1d1f;
}

.messageContent.recieveImg {
  background: transparent;
  border-radius: 10px 10px 10px 0px;
  color: #1d1d1f;
}

.messageContent.sent {
  background: #01b855;
  border-radius: 10px 10px 0px 10px;
  color: #ffffff;
}

.messageContent.sentImg {
  background: 'white';
  border-radius: 10px 10px 0px 10px;
  color: black;
}

/* .messageContent p {
  background-color: white;
  border-radius: 0px 10px 10px 10px;
} */

.message.owner {
  flex-direction: row-reverse;
}

.message.owner p {
  background-color: darkgreen;
  color: white;
  border-radius: 10px 0px 10px 10px;
}

.message.owner.messageContent {
  align-items: flex-end;
}

.user-profilepic {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.chat-header {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 5px;
}

.chatInfo {
  justify-self: end;
  text-align: end;
}

.profile-container {
  position: relative;
}

.profile-container2 {
  position: relative;
}

.profile-container2 .onlineDot {
  position: absolute;
  height: 12px;
  bottom: 0;
  right: 35px;
  width: 12px;
  border-radius: 50%;
  background-color: rgb(8, 180, 8);
}

.profile-container2 .offlineDot {
  position: absolute;
  height: 12px;
  bottom: 0;
  right: 35px;
  width: 12px;
  border-radius: 50%;
  background-color: #cecece;
  margin-left: -15px !important;
}

.offlineDot {
  /* position: absolute; */
  height: 12px;
  bottom: 0;
  right: 8px;
  width: 12px;
  border-radius: 50%;
  background-color: #cecece;
  /* margin-left: -15px !important; */
}

.onlineDot {
  /* position: absolute; */
  height: 12px;
  bottom: 0;
  right: 8px;
  width: 12px;
  border-radius: 50%;
  background-color: rgb(8, 180, 8);
  /* margin-left: -15px !important; */
}

.notification_div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #064ff5e8;
  position: relative;
  justify-self: end;
}

.noti_num {
  padding: 2px 5px !important;
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height */

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

/**Call modal**/
.call-modal {
  position: absolute;
  width: 400px;
  padding: 20px;
  left: calc(50vw - 200px);
  top: calc(50vh - 60px);
  background-image: linear-gradient(to top, #074055 0%, #030d10 100%);
  border-radius: 10px;
  text-align: center;
  display: none;
}

.call-modal.active {
  display: block;
  z-index: 9999;
  animation: blinking 3s infinite linear;
}

.call-modal .btn-action:not(.hangup) {
  background-color: white;
}

.call-modal p.caller {
  color: white;
}

.call-modal p {
  font-size: 1.5em;
}

@keyframes blinking {
  25% {
    transform: scale(0.96);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.96);
  }
  100% {
    transform: scale(1);
  }
}

/* Action button */
.btn-action {
  outline: none;
  border: none;
}

/* Call window */
.call-window {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s;
  background-image: linear-gradient(to top, #074055 0%, #030d10 100%);
}

.call-window.active {
  opacity: 1;
  z-index: 99999999;
}

.call-window.active .video-control {
  z-index: 999999999;
  /* animation: in-fadeout 3s ease; */
}

.video-control {
  position: absolute;
  bottom: 20px;
  height: 72px;
  width: 100%;
  text-align: center;
  opacity: 1;
  z-index: -1;
  /* transition: opacity 0.5s; */
}

.video-control1 {
  position: absolute;
  top: 250px;
  height: 200px;
  width: 100%;
  text-align: center;
  opacity: 1;
  /* opacity: 0;
  z-index: -1;
  transition: opacity 0.5s; */
}

.video-control:hover {
  /* opacity: 1; */
}

.videoScreen {
  position: absolute;
}

#localVideo {
  bottom: 0;
  right: 0;
  width: 20%;
  height: 20%;
}

#peerVideo {
  width: 100%;
  height: 100%;
}

@keyframes in-fadeout {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.video-control .btn-action {
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin: 0px 8px;
  font-size: 1.4em;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition-duration: 0.25s;
  background-color: white;
  padding: 6px 9px;
}

#call_only {
  background-color: #01b855;
}

.video-control .btn-action:hover {
  opacity: 0.8;
}

.video-control .btn-action.hangup {
  background-color: red;
  border: none;
  /* transform: rotate(135deg); */
}

.video-control .btn-action:not(.hangup) {
  background-color: white;
}

.video-control .btn-action:not(.hangup).disabled {
  /* background-color: red; */
}

/* main window */
.main-window {
  padding-top: 80px;
  font-size: 1.75em;
}

@media screen and (max-width: 767px) {
  .main-window {
    padding-top: 40px;
  }

  .pull-left,
  .pull-right {
    width: 100%;
    text-align: center;
  }

  .pull-right {
    margin-top: 20px;
  }
}

.btn-action {
  height: 60px;
  width: 60px;
  margin: 20px 30px 0px 0px;
  text-align: center;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition-duration: 0.25s;
  background-color: red;
  padding: 11px 15px;
}

.btn-action:hover {
  background-color: rgba(#ffffff, 0.2);
}

.txt-clientId {
  height: 40px;
  margin: 40px auto 0px 10px;
  color: #ffffff;
  font-size: 0.9em;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px #ffffff;
}

.txt-clientId::-webkit-input-placeholder {
  color: rgba(#ffffff, 0.8);
}

.txt-clientId::-moz-placeholder {
  color: rgba(#ffffff, 0.8);
}

.txt-clientId:-ms-input-placeholder {
  color: rgba(#ffffff, 0.8);
}

.txt-clientId::-ms-input-placeholder {
  color: rgba(#ffffff, 0.8);
}

.txt-clientId::placeholder {
  color: rgba(#ffffff, 0.8);
}
::ng-deep.swal2-container {
  z-index: 1000000;
}

.css-1gz45dj-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #3d71ff;
  color: white;
  border-radius: 30px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-fxhaud-MuiGrid-root:hover {
  background-color: rgb(221, 219, 219) !important;
}

.my-swal {
  z-index: 99999 !important;
}

/* input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none !important;
} */

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  visibility: hidden;
}

/* Remove visual search icon in Microsoft Edge for images with class 'login_safal_img' */
img.login_safal_img:not([draggable]),
img.login_safal_img:not([draggable]):hover {
  -ms-user-select: none;
  pointer-events: none;
}

.new-media-css {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.new-media-css.flex-start {
  align-items: flex-start;
}
.new-media-css .download-link {
  height: 30px;
  width: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: absolute;
  right: 6px;
  bottom: 6px;
  color: rgba(0, 0, 0, 0.5);
}
.new-media-css .download-link-video {
  height: 30px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: absolute;
  right: -36px;
  bottom: 6px;
  color: rgba(255, 255, 255, 0.5);
}
.new-media-css .download-link:hover {
  color: rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
}
.new-media-css .download-link-video:hover {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}
.new-media-css .message-type-file {
  width: 100%;
  background-color: #01b855;
  white-space: nowrap;
  padding: 9px 50px;
  color: white;
  border-radius: 8px;
}
.new-media-css .message-type-file.not-self {
  background-color: #eaeaea;
  color: #1d1d1f;
}

.display-version {
  color: transparent;
  cursor: help;
}
.display-version:hover {
  color: black;
}
.support-search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  margin: 8px 0px;
}
.support-search #support-search-input {
  background-color: white;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
  width: 100%;
  font-size: 16px;
  padding: 8px 12px;
  padding-right: 12%;
  outline: none;
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}
.support-search .support-search-btn {
  position: absolute;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 6%;
  border-radius: 9999%;
  color: #01b855;
}
.support-search .support-search-btn:hover {
  background-color: #01b855;
  color: white;
}
@media (min-width: 640px) {
  .closeOnDesktop {
    display: none;
  }
}

@media (max-width: 800px) {
  .sm-flex-wrap-center {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .w-full-720 {
    width: 100%;
  }
}
@media (max-width: 850px) {
  .w-full-break-x {
    width: 100% !important;
  }
  .w-full-break-x-flex {
    flex-direction: column;
  }
}
@media (max-width: 1100px) {
  .sm-flex-wrap-new {
    flex-wrap: wrap;
  }
}
@media (max-width: 1000px) {
  .flex-col-1000px {
    flex-direction: column;
  }
  .flex-col-rev-1000px {
    flex-direction: column-reverse;
  }
  .width-full-1000px {
    width: 100% !important;
  }
}

.icon-white img {
  filter: invert(1);
}

@media (max-width: 640px) {
  .messages {
    padding: 0px;
  }
  .message-container.sent {
    padding: 0px !important;
  }
  .message-container.receive {
    padding: 0px 8px !important;
  }
  .sidebar {
    padding: 20px 8px;
  }
  .sidebar.chatResponsiveToggle {
    width: 0;
    flex: none;
    overflow: hidden;
    padding: 0;
  }
  .chat.chatResponsiveToggle {
    width: 0;
    flex: none;
    overflow: hidden;
    padding: 0;
  }
  .chat-close-responsive {
    width: 22px;
    margin-left: 4px !important;
  }
  .chat-nav-right-responsive {
    padding: 5px !important;
  }
  .chat-nav-right-icon {
    padding: 6px !important;
    width: 32px !important;
    height: 32px !important;
  }
  #chatInput_chatInput_chatInput::placeholder {
    font-size: 12px;
  }

  .settingPage-div-1 {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .settingPage-div-total {
    top: 10% !important;
  }
}

.SocialMedia-Blur {
  filter: blur(6px);
}

.SocialMedia-Blur-Content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.SocialMedia-Blur-Content span {
  font-size: 32px;
  font-weight: bold;
}
.react-mobile-app-button-style .button-text-container .button-title {
  font-size: 10px;
}
.react-mobile-app-button-style .button-text-container .button-store-name {
  font-size: 12px;
}

.MuiAccordionSummary-content {
  align-items: center !important;
}

div.carousel.carousel-slider > ul.control-dots {
  margin: 0;
  margin-bottom: 8px;
}

div.carousel.carousel-slider > ul.control-dots > li.dot {
  background-color: #bcceff;
  opacity: 1;
  box-shadow: none;
}
div.carousel.carousel-slider > ul.control-dots > li.dot.selected {
  background-color: #3d71ff;
  opacity: 1;
  box-shadow: none;
}

:root {
  --md-editor-background-color: white;
  --color-fg-default: black;
}

.filter-sub-label-x2 {
  color: #637381;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.hover-underline:hover {
  text-decoration: underline;
}

/* HTML: <div class="unique-loader-line"></div> */

.unique-loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  /* margin: 100px auto; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.unique-loader-line:before {
  content: '';
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  z-index: 100;
  background-color: #bcceff;
  -webkit-animation: uniqueLineAnim 1s linear infinite;
  -moz-animation: uniqueLineAnim 1s linear infinite;
  animation: uniqueLineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes uniqueLineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.hover-blue-border-xz1:hover {
  border-color: #3d71ff !important;
}

.text-overflow-line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border-radius: 30px;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  border-radius: 30px;
}

.custom_mentions__mention_user {
  position: relative;
  z-index: 1;
  color: #3d71ff;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  pointer-events: none;
}
.custom_mentions__mention_room {
  position: relative;
  z-index: 1;
  color: #02b757;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
  pointer-events: none;
}

.scroll-hide {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scroll-hide::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.custom_mentions__mention_user_display {
  color: #3d71ff;
  cursor: pointer;
}
.custom_mentions__mention_room_display {
  color: #02b757;
  cursor: pointer;
}

.modernWay-max-lines-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.modernWay-max-lines-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.button-bottom-bar-buddy-select {
  -webkit-box-shadow: inset 0px -2px 0px 0px rgba(9, 50, 161, 1);
  -moz-box-shadow: inset 0px -2px 0px 0px rgba(9, 50, 161, 1);
  box-shadow: inset 0px -2px 0px 0px rgba(9, 50, 161, 1);
}

#Update-Confirmation-Message {
  padding: 4px 12px;
  color: black;
  border-radius: 8px;
  z-index: 9999999 !important;
}

.new-input {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.new-input .att-close-btn {
  height: 20px;
  width: 20px;
  background-color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.new-input .att-div {
  gap: 4px;
  height: 84px;
  display: flex;
  align-items: center;
  overflow: auto;
}
.new-input .att {
  border: 1px solid rgb(100, 100, 100);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  flex: none;
  height: 64px;
}
.new-input .typing {
  background-color: #f3f3f3;
  border-radius: 8px;
  font-size: 14px;
  padding: 3px 8px;
}
.new-input .input-div {
  margin-right: 4px;
  border-radius: 8px;
  overflow: auto;
  background-color: #f3f3f3;
  display: flex;
  flex: 1 1 0%;
  align-items: center;
}

.new-input .input-div textarea {
  flex: 1 1 0%;
  height: 48px;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  padding: 12px;
  resize: none;
  overflow: auto;
}
.new-input .input-div label {
  height: 40px;
  width: 40px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  outline: none;
  flex: none;
}

.send-btn {
  height: 48px;
  width: 48px;
  background-color: #01b855;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  outline: none;
  flex: none;
}
.send-btn:disabled {
  background-color: #01b8565d;
}

.progress-infinite {
  height: 4px;
  border-radius: 8px;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
}
.disableInputDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

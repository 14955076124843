#UserAssessmentBusiness {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

#UserAssessmentBusiness .title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  margin-top: 12px;
}

#UserAssessmentBusiness .title-bar .title-search-div {
  display: flex;
  align-items: center;
  gap: 32px;
}
#UserAssessmentBusiness .title-bar .title-search-div > h1 {
  color: #3d71ff;
}
#UserAssessmentBusiness .toggle-bar-dix {
  display: flex;
  align-items: center;
}
#UserAssessmentBusiness .CheckboxAction {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

#UserAssessmentBusiness .toggle-bar {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

#UserAssessmentBusiness .main-pagination {
  display: flex;
  justify-content: end;
}

#UserAssessmentBusiness .title-action-div {
  flex: none;
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
}
#UserAssessmentBusiness .card-view-switch {
  display: flex;
  align-items: center;
}

#UserAssessmentBusiness .main-content {
  border-radius: 12px;
  flex: 1 1 0%;
  background-color: white;
  overflow: auto;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px;
}

@media (max-width: 1000px) {
  #UserAssessmentBusiness .main-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 16px;
    gap: 16px;
  }
}

@media (max-width: 700px) {
  #UserAssessmentBusiness .main-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 12px;
    gap: 12px;
  }
  #UserAssessmentBusiness .title-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #UserAssessmentBusiness .title-bar .title-search-div {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
    gap: 12px;
  }
  .sm-flex-direction-column {
    flex-direction: column;
  }
  #UserAssessmentBusiness .title-action-div {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  #UserAssessmentBusiness .toggle-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #UserAssessmentBusiness .CheckboxAction {
    margin-left: 8px;
  }
}

#ManageCall {
  height: 100%;
  overflow: auto;
}
#ManageCall .action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 12px;
}

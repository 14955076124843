/* SafalEvent */

#SafalEvent .top-btn {
  border-radius: 99999px;
  border: 1px solid #3d71ff;
  padding: 8px 20px;
  font-size: 16px;
  color: #3d71ff;
  background-color: white;
  width: 100px;
  cursor: pointer;
}
#SafalEvent .top-btn-active {
  border-radius: 99999px;
  border: 1px solid #3d71ff;
  padding: 8px 20px;
  font-size: 16px;
  color: white;
  background-color: #3d71ff;
  width: 100px;
  cursor: pointer;
}

#NewCallLogModal {
}

#NewCallLogModal .log-title {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#NewCallLogModal .log-title span {
  font-size: 20px;
  margin-left: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
#NewCallLogModal .log-title button {
  border: none;
  outline: none;
  padding: 4px 8px;
  border-radius: 10px;
}

#FaqAIModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  flex: 1;
  overflow: hidden;
}
#FaqAIModal .head-icon {
  display: flex;
  align-items: center;
  justify-content: start;
}
#FaqAIModal .title {
  color: #3d71ff;
  font-size: 20px;
  font-weight: bold;
  padding: 0px 12px;
}
#FaqAIModal .border-blue {
  margin-top: 10px;
  height: 4px;
  background-color: #3d71ff;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 8px;
}
#FaqAIModal .ai-chat {
  background-color: white;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  margin: 16px 0px;
  min-height: 200px;
  overflow: auto;
  max-height: 50vh;
}
#FaqAIModal .ai-chat .ai-chat-message {
  display: flex;
  padding: 4px 32px;
  gap: 12px;
}

#FaqAIModal .ai-chat .ai-chat-message.self {
  flex-direction: row-reverse;
}

#FaqAIModal .ai-chat .ai-chat-message .image-div {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  overflow: hidden;
  flex: none;
}
#FaqAIModal .ai-chat .ai-chat-message .image-div .ai-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
}
#FaqAIModal .user-input {
  /* background-color: #3d71ff; */
  background-color: white;
  display: flex;
  padding: 16px 32px;
  gap: 16px;
  flex: none;
}
#FaqAIModal .user-input .ai-user-input {
  background-color: #f3f3f3;
  flex: 1;
  font-size: 18px;
  padding: 2px 8px;
  border-radius: 8px;
  border: 1px solid white;
  outline: 1px solid #cfcfcf;
}
#FaqAIModal .user-input .ai-user-input:focus {
  outline: 1px solid #cfcfcf;
}
#FaqAIModal .user-input .ai-user-input:hover {
  outline: 1px solid rgb(37, 37, 37);
}
#FaqAIModal .user-input .mic-btn {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #01b855 0%, #0792ef 100%);
  height: 40px;
  width: 40px;
  border-radius: 10px;
}
#FaqAIModal .user-input .send-btn {
  display: flex;
  align-items: center;
  background-color: #01b855;
  height: 40px;
  width: 40px;
  border-radius: 10px;
}

#FaqAIModal .user-input .mic-btn:hover {
  background-color: white;
}
#FaqAIModal .user-input .mic-btn.on {
  background-color: #df3e30;
  -webkit-box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
  -moz-box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
  box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
  animation: box-shadow-animation 2s infinite;
}
#FaqAIModal .transcript {
  margin-top: 8px;
  font-style: italic;
}

@keyframes box-shadow-animation {
  0% {
    -webkit-box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
    -moz-box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
    box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
  }
  50% {
    -webkit-box-shadow: 0px 0px 0 5px #ffffff, 0px 0px 0 8px #df3e30;
    -moz-box-shadow: 0px 0px 0 5px #ffffff, 0px 0px 0 8px #df3e30;
    box-shadow: 0px 0px 0 5px #ffffff, 0px 0px 0 8px #df3e30;
  }
  100% {
    -webkit-box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
    -moz-box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
    box-shadow: 0px 0px 0 2px #ffffff, 0px 0px 0 4px #df3e30;
  }
}

.new-head-tab-right {
  text-transform: capitalize;
  flex: 1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.new-head-tab-right:hover {
  outline: 1px solid black;
}
.new-head-tab-left {
  text-transform: capitalize;
  flex: 1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: none;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  background-color: white;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.new-head-tab-left:hover {
  outline: 1px solid black;
}
.new-head-tab-active {
  background: linear-gradient(90deg, rgba(239, 138, 12, 1) 0%, rgba(7, 146, 239, 1) 100%);
  color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.new-head-tab-active-1 {
  background: linear-gradient(90deg, rgba(7, 146, 239, 1) 0%, rgba(239, 138, 12, 1) 100%);
  color: white;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

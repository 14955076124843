#DynamicMenu {
  height: 100%;
  overflow: auto;
}
#DynamicMenu .action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 12px;
}
#DynamicMenu .select-bar {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 16px;
  padding: 0px 12px;
  gap: 12px;
}

/* sdad */
/* #UserOffersList {
  background-color: blue;
} */

.ufl-w-full-600 {
  width: 350px;
}
.ufl-w-full-600-500 {
  width: 600px;
}
.carouselContentOfferStyle {
  margin: 0;
  height: 160px;
  /* lineHeight: '160px', */
}
.carouselContentOfferStyle img {
  height: 160px;
  object-fit: cover;
  /* lineHeight: '160px', */
}

.cat-table-grid-x {
  margin: 16px 0px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 8 columns */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 16px; /* Space between grid items */
}
/* Responsive adjustments */
@media (max-width: 1200px) {
  .cat-table-grid-x {
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
  }
}

@media (max-width: 768px) {
  .cat-table-grid-x {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
  }
}

@media (max-width: 480px) {
  .cat-table-grid-x {
    grid-template-columns: repeat(2, 1fr); /* 2 column */
  }
}

@media (max-width: 600px) {
  .ufl-w-full-600 {
    width: 100%;
  }
  .ufl-w-full-600-500 {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .ufl-hide-in-450 {
    display: none;
  }
}
.hover-bg-white-x:hover {
  background-color: white;
}
.hover-bg-white-x2:hover {
  background-color: #f2f2f2;
}

/* HTML: <div class="loader"></div> */
.loader-x33 {
  height: 4px;
  width: 100%;
  --c: no-repeat linear-gradient(#3d71ff 0 0);
  background: var(--c), var(--c), #bcceff;
  background-size: 60% 100%;
  animation: l16 3s infinite;
  border-radius: 8px;
}
@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}

.offer-link-btn {
  border: none;
  background-color: transparent;
  font-size: 16px;
  height: 20px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  flex: none;
}
.offer-link-btn:hover {
  text-decoration: underline;
}

#SafalEventBusinessForm {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 12px;
}

#SafalEventBusinessForm .title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  margin-top: 12px;
}

#SafalEventBusinessForm .title-bar .title-search-div {
  display: flex;
  align-items: center;
  gap: 32px;
}
#SafalEventBusinessForm .title-bar .title-search-div > h1 {
  color: #3d71ff;
}
#SafalEventBusinessForm .toggle-bar-dix {
  display: flex;
  align-items: center;
}
#SafalEventBusinessForm .CheckboxAction {
  margin-left: 24px;
  display: flex;
  align-items: center;
}

#SafalEventBusinessForm .toggle-bar {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

#SafalEventBusinessForm .main-pagination {
  display: flex;
  justify-content: end;
}

#SafalEventBusinessForm .title-action-div {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
}
#SafalEventBusinessForm .card-view-switch {
  display: flex;
  align-items: center;
}

#SafalEventBusinessForm .main-content {
  border-radius: 12px;
  flex: 1 1 0%;
  background-color: white;
  overflow: auto;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px;
}

@media (max-width: 1000px) {
  #SafalEventBusinessForm .main-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 16px;
    gap: 16px;
  }
}

@media (max-width: 700px) {
  #SafalEventBusinessForm .main-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 12px;
    gap: 12px;
  }
  #SafalEventBusinessForm .title-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #SafalEventBusinessForm .title-bar .title-search-div {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .sm-flex-direction-column {
    flex-direction: column;
  }
  #SafalEventBusinessForm .title-action-div {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  #SafalEventBusinessForm .toggle-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #SafalEventBusinessForm .CheckboxAction {
    margin-left: 8px;
  }
}

/* Override Ant Design image preview mask z-index */
#SafalEventBusinessForm .ant-image-preview-mask {
  z-index: 1050; /* Adjust to a high number to ensure it's on top */
}

/* Override Ant Design image preview image container z-index */
#SafalEventBusinessForm .ant-image-preview-wrap {
  z-index: 1050; /* Match the z-index value */
}

#DynamicFormDiv > div > div > div {
  display: flex;
  gap: 8px;
}

#DynamicFormDiv .alert.alert-danger.validation-error {
  align-items: center;
}
#DynamicFormDiv .react-form-builder .react-form-builder-preview {
  width: 70%;
}
#DynamicFormDiv .react-form-builder .react-form-builder-toolbar {
  width: 30%;
}
#DynamicFormDiv .react-form-builder-form .btn-toolbar {
  justify-content: flex-end;
}
#DynamicFormDiv .label-required.badge.badge-danger {
  color: red;
}

#AddSubscriptionAnimation {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#AddSubscriptionAnimation img {
  width: 400px;
  object-fit: cover;
}

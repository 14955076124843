#SafalLeaderBoard {
  margin-top: 40px;
}

.SafalLeaderBoard-rank {
  width: 600px;
}

.SafalLeaderBoard-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.SafalLeaderBoard-hr {
  width: 2px;
  height: 95%;
  background-color: #9790cd;
  margin: auto 0px;
  margin-right: 20px;
}

/* @media (max-width: 1000px) {
  .SafalLeaderBoard-rank {
    width: 400px;
  }
} */

@media (max-width: 1200px) {
  #SafalLeaderBoard {
    flex-direction: column;
  }
  .SafalLeaderBoard-rank {
    width: 100%;
  }
  .SafalLeaderBoard-hr {
    height: 2px;
    width: 95%;
    margin: 0px auto;
  }
}

.SafalLeaderBoard-rank-number {
  font-family: 'Keania One', sans-serif;
  font-weight: 400;
  font-style: normal;
}
.SafalLeaderBoard-user-title {
  font-family: 'Katibeh', serif;
  font-weight: 400;
  font-style: normal;
}

/* HTML: <div class="trapezoid"></div> */
.SafalLeaderBoard-trapezoid {
  --p: 20px; /* control the shape (can be percentage) */
  height: 180px;
  aspect-ratio: 3/2;
  clip-path: polygon(var(--p) 0, calc(100% - var(--p)) 0, 100% 100%, 0 100%);
}

#NewChatAndCall {
  display: flex;
  height: 100%;
}

#NewChatAndCall #NewChatUserList .logo {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #1d1d1f;
}

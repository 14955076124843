#InitialFeedbackModal .SmileyRating-img:hover {
  scale: 1.25;
}

#InitialFeedbackModal .SmileyRating-img {
  transition: scale 0.3s ease-out;
}

#InitialFeedbackModal .SmileyRating-img.not-selected {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

#InitialFeedbackModal .SmileyRating-img.selected {
  scale: 1.25;
}

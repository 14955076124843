.BusinessDashboard-grid-1 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 16px;

  grid-template-columns: 2fr repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.BusinessDashboard-grid-1 > :nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}
.BusinessDashboard-grid-1 > :nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}
.BusinessDashboard-grid-1 > :nth-child(3) {
  grid-area: 1 / 3 / 2 / 4;
}

.BusinessDashboard-grid-2 {
  display: grid;
  gap: 16px;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}
.BusinessDashboard-grid-2 > :nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}
.BusinessDashboard-grid-2 > :nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}
.BusinessDashboard-grid-2 > :nth-child(3) {
  grid-area: 1 / 3 / 2 / 4;
}
.BusinessDashboard-grid-2 > :nth-child(4) {
  grid-area: 1 / 4 / 2 / 5;
}

.BusinessDashboard-grid-3 {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.BusinessDashboard-grid-3 > :nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}
.BusinessDashboard-grid-3 > :nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}

.BusinessDashboard-country-chart {
  display: grid;
  gap: 16px;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
}
.BusinessDashboard-country-chart > :nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}
.BusinessDashboard-country-chart > :nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
  border-left: 2px solid #468b47;
  border-top: none;
}

@media screen and (max-width: 600px) {
  .BusinessDashboard-MainAction {
    gap: 8px !important;
  }
  .BusinessDashboard-MainAction-btn {
    padding: 4px 16px !important;
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1000px) {
  .BusinessDashboard-grid-1 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .BusinessDashboard-grid-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .BusinessDashboard-grid-1 > :nth-child(1) {
    grid-area: 1 / 1 / 2 / 3;
  }
  .BusinessDashboard-grid-1 > :nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .BusinessDashboard-grid-1 > :nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .BusinessDashboard-grid-2 > :nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .BusinessDashboard-grid-2 > :nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }

  .BusinessDashboard-grid-3 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
  .BusinessDashboard-grid-3 > :nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }

  .BusinessDashboard-country-chart {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }
  .BusinessDashboard-country-chart > :nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  .BusinessDashboard-country-chart > :nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
    border-left: none;
    border-top: 2px solid #468b47;
  }
}

.BusinessDashboard-arrow-up {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 9px solid #90ee90;
}
.BusinessDashboard-arrow-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 9px solid #fcbaba;
}

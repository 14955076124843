#AssetModule {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 12px;
}

#AssetModule .title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: none;
  margin-top: 12px;
}

#AssetModule .title-bar .title-search-div {
  display: flex;
  align-items: center;
  gap: 12px;
}
#AssetModule .title-bar .title-search-div > h1 {
  color: #3d71ff;
}
#AssetModule .toggle-bar-dix {
  display: flex;
  align-items: center;
}
#AssetModule .CheckboxAction {
  margin-right: 24px;
  display: flex;
  align-items: center;
}

#AssetModule .toggle-bar {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: end;
}

#AssetModule .main-pagination {
  display: flex;
  justify-content: end;
}

#AssetModule .title-action-div {
  flex: none;
  display: flex;
  gap: 12px;
  align-items: center;
}
#AssetModule .card-view-switch {
  display: flex;
  align-items: center;
}

#AssetModule .main-content {
  border-radius: 12px;
  flex: 1 1 0%;
  background-color: white;
  overflow: auto;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 20px;
}

#AssetModule #asset-card {
  width: 100%;
  height: 160px;
  border-radius: 12px;
  position: relative;
  background-color: white;
}
.card-add-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
}

#AssetModule #asset-card .card-main {
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20px);
  margin-top: 20px;
  padding-left: 130px;
  padding-right: 6px;
  padding-top: 10px;
  /* gap: 12px; */
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  border: none;
}
#AssetModule #asset-card .card-main:hover {
  background-color: #dae0f1;
  cursor: pointer;
}

#AssetModule #asset-card .card-main .card-name {
  color: #1e82e6;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#AssetModule #asset-card .card-main .div-br {
  background-color: #c1c8f6;
  height: 4px;
  width: 40%;
}

#AssetModule #asset-card .card-main .card-number {
  color: black;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  margin: 8px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}
#AssetModule #asset-card .card-points {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: start;
}

#AssetModule #asset-card .card-image {
  position: absolute;
  top: 0px;
  left: 20px;
}
#AssetModule #asset-card .card-image > img {
  width: 100px;
  border-radius: 6px;
  height: 100px;
  object-fit: cover;
}
#AssetModule #asset-card .card-image > .card-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

#AssetModule #asset-card .expire-bar {
  bottom: 0px;
  width: 100%;
  height: 32px;
  background-color: #ff3b30;
  position: absolute;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}
#AssetModule #asset-card .inactive-bar {
  bottom: 0px;
  width: 100%;
  height: 32px;
  background-color: #a7b1bc;
  position: absolute;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}

@media (max-width: 1000px) {
  #AssetModule .main-content {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 16px;
    gap: 16px;
  }

  #AssetModule #asset-card .card-main {
    padding-left: 132px;
    padding-top: 16px;
    margin-top: 16px;
  }

  #AssetModule #asset-card .card-image {
    left: 16px;
  }
}

@media (max-width: 700px) {
  #AssetModule #asset-card .card-main {
    padding-left: 124px;
    margin-top: 12px;
    padding-top: 12px;
  }

  #AssetModule #asset-card .card-image {
    left: 12px;
  }

  #AssetModule .main-content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 12px;
    gap: 12px;
  }
  #AssetModule .title-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #AssetModule .title-bar .title-search-div {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 12px;
    gap: 12px;
  }
  .sm-flex-direction-column {
    flex-direction: column;
  }
  #AssetModule .title-action-div {
    flex-wrap: wrap;
    align-items: flex-end;
  }
  #AssetModule .toggle-bar {
    flex-direction: column;
    align-items: flex-start;
  }
  #AssetModule .CheckboxAction {
    margin-right: 8px;
  }
}

.qr-reader {
  width: 350px;
  height: 350px;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-box.scan-region-highlight {
  height: 0px !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(0);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
    height: 76vw;
  }

  .qr-reader .qr-frame {
    transform: translateX(-50%) translateY(-10%);
  }
}
